@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: 'Rubik', sans-serif;
  --bg: #EEEEEE;
  --onBg: #212121;

  --primary: #304FFE; 
  --onPrimary: #E8EAF6;
  
  --secondary: #6D4C41; 
  --onSecondary: #EFEBE9;

  --border-radius: 6px;
  --box-shadow: 0 1px 3px rgba(0, 0, 0.12), 0 1px 2px rgba(0, 0, 0.24);

  &.isDark {
    --bg: #212121;
    --onBg: #EEEEEE;
  
    --primary: #1A237E; 
    --onPrimary: #E8EAF6;
    
    --secondary: #3E2723; 
    --onSecondary: #EFEBE9;
  }

}

* {
  transition: background-color 1s ease-in-out;
}


body {
  margin: 0;
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* NOTE
html is set to 62.5% so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
  font-size: 62.5%; }
body {
  font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
  line-height: 1.75;
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--onBg); }


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: bold;
}
h1 { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;}
h2 { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem; }
h3 { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem; }
h4 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
h5 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0; }

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 5.0rem; }
  h2 { font-size: 4.2rem; }
  h3 { font-size: 3.6rem; }
  h4 { font-size: 3.0rem; }
  h5 { font-size: 2.4rem; }
  h6 { font-size: 1.5rem; }
}

p {
  margin-top: 0; 
  max-width: 600px;
  margin-bottom: 15px;
}

a {
  color: var(--color-primary-900);
  text-decoration: none;
  font-weight: bold;

  &:visited {
    color: var(--color-primary-900);
  }
}

ul {
  padding: 0;
  li {
    list-style: none;
  }
}

.list-style {
  padding-left: 15px;
  li {
    margin-bottom: 15px;
    list-style: disc;
  }
}

.text-center {
  text-align: center;
}

*::selection {
  color: var(--bg);
  background-color: var(--onBg);
}


body {
  color: var(--onBg);
  background-color: var(--bg);
}

* {
  color: var(--onBg);
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

html{
  scroll-behavior:smooth;
}

.italic {
  font-style: italic;
}